import React from 'react'
import { Link } from 'gatsby'
import moment from 'moment'
import Disqus from '../Disqus/Disqus'
import Helmet from 'react-helmet'
import './style.scss'
import MailChimpForm from '../MailChimpForm'
import SEO from "../Seo"

class PostTemplateDetails extends React.Component {
  render() {
    const { subtitle, author } = this.props.data.site.siteMetadata
    const post = this.props.data.markdownRemark
    const {tagSlugs, slug }= post.fields
    const readingTime= post.fields.readingTime.text
    const {
		title,
		description,
		tags
    } = this.props.data.markdownRemark.frontmatter
    const {next, previous } = this.props.pageContext
    const homeBlock = (
      <div>
        <Link className="post-single__home-button" to="/">
          All Articles
        </Link>
      </div>
    )

    const tagsBlock = (
      <div className="post-single__tags">
        <ul className="post-single__tags-list">
          {tagSlugs &&
            tagSlugs.map((tag, i) => (
              <li className="post-single__tags-list-item" key={tag}>
                <Link to={tag} className="post-single__tags-list-item-link">
                  {post.frontmatter.tags[i]}
                </Link>
              </li>
            ))}
        </ul>
      </div>
    )

    const commentsBlock = (
      <div>
        <Disqus
          postNode={post}
          siteMetadata={this.props.data.site.siteMetadata}
        />
      </div>
	)

  const nextArticle = next && next.frontmatter.path.startsWith("/posts") && (
    <li>
      <strong>Next Article</strong> <br/>
      <Link to={next.frontmatter.path} style={{ maxWidth: '25%'}}>
        {next.frontmatter.title}
      </Link>
    </li>
  )

  const prevArticle = previous && previous.frontmatter.path.startsWith("/posts") && (
  <li>
    <strong>Previous Article</strong> <br/>
    <Link to={previous.frontmatter.path} style={{ maxWidth: '25%'}}>
      {previous.frontmatter.title}
    </Link>
  </li>
  )

  const SeoBlock = (
    <div>
      <SEO
        location={this.props.location.pathname}
        title={post.frontmatter.title}
        description={post.frontmatter.description || post.excerpt}
      />
    </div>
  )
    return (
      <div>
        {homeBlock}
        {SeoBlock}
        <center><div /* top_of_page */ id="ezoic-pub-ad-placeholder-102"> </div></center>
        <div /* sidebar_top_right */ align="right" id="ezoic-pub-ad-placeholder-103"> </div>
        <div className="post-single">       
        <div /* sidebar_top_left */ align="left" id="ezoic-pub-ad-placeholder-104"> </div>
          <div className="post-single__inner">
            <h1 className="post-single__title">{post.frontmatter.title}</h1>
            <p className="post-single__date_and_reading_time">
              <em>
                <center>
                Published {moment(post.frontmatter.date).format('D MMM YYYY')} - {readingTime}
                </center>
              </em>
            </p>
            <div
              className="post-single__body"
              /* eslint-disable-next-line react/no-danger */
              dangerouslySetInnerHTML={{ __html: post.html }}
            />
            <ul>
            {nextArticle} {prevArticle}
            </ul>
              <center>
                Get emails about new articles! <MailChimpForm />
              </center>
          </div>
          <center><div /* bottom_of_page_top */ id="ezoic-pub-ad-placeholder-105"> </div></center>
          <div className="post-single__footer">
            {tagsBlock}
            <hr />
            <p className="post-single__footer-text">
              {subtitle}
              <a
                href={`https://twitter.com/${author.twitter}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <br /> <strong>{author.name}</strong> on Twitter
              </a>
            </p>
            {commentsBlock}
            <center><div /* bottom_of_page */ id="ezoic-pub-ad-placeholder-101"> </div></center>
          </div>
        </div>
      </div>
    )
  }
}

export default PostTemplateDetails
